import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Link from 'components/Link';
import Layout from 'components/Layout';
import SEO from 'components/Seo';

import 'locales/i18n';

const SupportPage = ({ pageContext, location }) => {
  const { t, i18n } = useTranslation();
  const defaultLng = i18n.options.fallbackLng[0];
  i18n.changeLanguage(pageContext.lng || defaultLng);

  return (
    <Layout location={location}>
      <SEO
        title={t('support.title')}
        lang={i18n.language || 'fr'}
        metaTitle={t('meta.title')}
        metaDescription={t('meta.description')}
      />

      <div className="row my-6">
        <div className="col-md-6 col-sm-8 mx-auto">
          <h1>{t('support.title')}</h1>
          {/* eslint-disable-next-line react/no-danger */}
          <div dangerouslySetInnerHTML={{ __html: t('support.description') }} />
          <br />
          <Link to="/" className="btn btn-primary btn-sm mt-2">
            &lsaquo; {t('confirmation.button')}
          </Link>
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
        </div>
      </div>
    </Layout>
  );
};

SupportPage.propTypes = {
  pageContext: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};
SupportPage.defaultProps = {};

export default SupportPage;
